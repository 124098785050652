.dz-default {
    display: none;
}

.dragndrop-text {
    color: lightgrey;
    font-size: 20px;
    font-weight: 700;
    margin-top: 125px;
    text-align: center;
}

.dz-image,
.dz-details,
.dz-progress,
.dz-error-message {
    display: none !important;
}